.navi {
  padding: 5px 0;
  font-size: .85em;
  border-bottom: 1px solid #ccc;
  background-color: #fdfdfd;
  grid-area: navi;
  text-transform: uppercase;
}

.navi button {
  vertical-align: middle;
  font-size: .85em;
}

