pre {
  border: 1px solid #aaa;
  font-family: Monaco;
  font-size: 70%;
  max-height: 250px;
  overflow: scroll;
  padding: 5px;
  text-align: left;
}

.entity-error {
  background-color: #f73636;
  border: 1px solid #cc0202;
}

.entity-deleted {
  opacity: 0.5;
  pointer-events: none;
}

textarea {
  box-sizing: border-box;
  width: 100%;
}

button {
  text-transform: uppercase;
  font-size: 12px;
  padding: 3px 4px 2px;
  background: #bc131d;
  border: 1px solid #b55056;
  color: #eee;
  border-radius: 3px;
  min-width: 20px;
  vertical-align: top;
}

.buttonnext {
  margin-left: 4px;
}

button:hover {
  color: #222;
}

button:focus {
  outline:0;
}

button:disabled {
  background: #b55056;
}

.entity-editor {
}

.entity-editor-title {
  display: grid;
  grid-template-columns: 1fr 1fr;

  padding: 5px 10px;
  background: #eee;
}
.entity-editor-title h4 {
  margin: 0;
}


.entity-editor-toggle {
  align-self: center;
  justify-self: end;
  background: #eee;
}

.entity-editor-deleted {
  justify-self: end;
  color: #bc131d;
}

.entity-editor-content {
  grid-area: cont;
  border-top: 1px solid #ccc;
  padding: 5px 10px;
}

.entity-editor-content h5 {
  margin: 10px 0 0 0;
  font-size: .8em;
  text-transform: uppercase;
}

.entity-editor-content p {
  margin: .5em 0;
}

.production-header,
.non-production-header {
  padding: 10px;
  margin-top: 10px;
  background: #eee;
  border: 1px solid #ccc;
}

.production-header h1,
.non-production-header h1 {
  font-size: 1.5em;
  margin: 0 0 0px;
}

.production-header h2,
.production-header h3 {
  margin: 0;
}

.non-production-header {
  display: grid;
  grid-template-columns: 9fr 1fr;
}

.non-production-type {
  text-align: right;
}

.production-producer {
  grid-area: producer;
  font-size: 1em;
}
.production-year {
  margin-left: 10px;
}
.production-toggle {
  grid-area: toggle;
  justify-self: end;
}
.production-title {
  grid-area: title;
}
.production-genre {
  grid-area: genre;
  font-size: 0.9em;
}

.production-img-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  background: #fafafa;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.production-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.production-summary-box {
}

.entity-summary {
}

.production-category {
  text-transform: uppercase;
}

.entity-location {
  padding-top: 1em;
}

.entity-location input {
  width: 100%;
}

.entity-location-buttons {
  margin: 5px 0;
}

.entity-credit-box {
  margin-bottom: 20px;
}
.entity-credit-box-title {
  background: #eee;
  text-transform: uppercase;
  margin: 0;
  padding: 5px 10px;
  font-size: 0.9em;
  border: 1px solid #ccc;
}

.entity-credit-box-title input {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
}

.entity-credit-box-title-disabled-message {
  font-weight: normal;
  text-transform: none;
  color: #bc131d;
  padding-left: 10px;
}

.entity-credit-box-credits {}

.entity-credit {
  padding: 5px 10px;
  background: #fafafa;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.entity-credit-display {
  display: grid;
  grid-template-columns: 1fr 25px 1fr;
}

.entity-credit-edit {
  display: grid;
  grid-template-columns: 20px 1fr 25px 1fr;
}
.entity-credit-focus {}
.entity-credit-focus-pending {}
.entity-credit-focus-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.entity-credit-focus-form div {
  display: grid;
  justify-items: stretch;
}
.entity-credit-focus-buttons {
  margin-top: 5px;
}

.entity-credit-box-adder {
  padding-left: 10px;
  padding-top: 5px;
}