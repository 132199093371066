.registration-category,
.registration-location,
.registration-dates {
  display: flex;
}

.registration-dates label {
    width: 50%;
}

.registration-location label {
    width: 50%;
}

.registration-category select {
    margin-left: 5px;
    text-align: center;
}

.registration-location label:nth-child(2),
.registration-dates label:nth-child(2) {
    margin-left: 10px;
}


.registration-dates label input {
    width: 190px;
}