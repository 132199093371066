.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4 {
  margin: 0 0 0.2em 0;
}

.markdown h1 {
  font-size: 150%;
}

.markdown h2 {
  font-size: 125%;
}

.markdown h3 {
  font-size: 100%;
  text-transform: uppercase;
}

.markdown h4 {
  font-size: 85%;
  text-transform: uppercase;
}

.markdown ul,
.markdown ol {
  margin: 0.5em 0;
  padding: 0 0 0 25px;
}



