.page {
  text-align: center;
  display: grid;
  grid-template-areas:
    "navi"
    "spot"
    "edit"
    "lead"
    "cont";
}

.page-navi {
  text-align: center;
  display: grid;
  grid-gap: 0;
  grid-template-areas:
    "navi"
    "cont";
}

.page-cont {
  text-align: left;
  display: grid;
  grid-gap: 0;
  grid-template-areas:
    "spot"
    "edit"
    "lead"
    "cont";
  border-bottom: 1px solid #ccc;
}

.spot-figure {
  margin: 0;
}

.spot-image {
  width: 100%;
}

.spot {
  grid-area: spot;
}

.lead {
  grid-area: lead;
}

.cont {
  grid-area: cont;
}

.edit {
  grid-area: edit;
  margin-top: 10px;
  background: #fafafa;
  border: 1px solid #ccc;
  text-align: left;
}

.spot-emoji {
  margin-top: 0.25em;
  font-size: 7em;
}

@media screen and (min-width: 500px) {

  .page {
    grid-template-columns: 1fr 500px 1fr;
    grid-template-areas:
      "navi navi navi"
      "xxxx spot yyyy"
      "xxxx edit yyyy"
      "xxxx lead yyyy"
      "xxxx cont yyyy";
  }

  .page-cont {
    grid-template-columns: 1fr 500px 1fr;
    grid-template-areas:
      "xxxx spot yyyy"
      "xxxx edit yyyy"
      "xxxx lead yyyy"
      "xxxx cont yyyy";
  }

}


